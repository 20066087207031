.main-nav {
    padding: 12px 0;
    margin-bottom: 48px;

    @include lg-up {
        margin-bottom: 80px;
        padding: 20px 0;
    }

    .navbar-collapse {
        .navbar-nav {
            margin-top: calc(48px - 12px);

            @include lg-up {
                margin-top: 0;
            }
        }
    }

    .nav-link {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #60758f;
        padding-top: 12px;
        padding-bottom: 12px;

        @include lg-up {
            margin-right: 24px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        @include xl-up {
            margin-right: calc(56px - 1rem);
        }
    }

    .nav-item {
        &:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }
}

.topbar {
    padding: 12px 0;
}

.menu-opened {
    position: fixed;
    background: white;
    z-index: 999;
    top: 0;
}

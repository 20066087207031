.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 56px;
    border-radius: $border-radius;
    line-height: 1;
    transition: 0.2s all;
    padding: 0.625rem 2rem;

    &.small {
        height: 40px;
        border-radius: calc($border-radius / 2);
    }
}

.btn-primary {
    color: white;
    font-weight: bold;

    &:hover,
    &:focus,
    &:disabled,
    &.disabled {
        color: white !important;
    }
}

.btn-link-arrow-right {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $primary;
    font-weight: bold;
    text-decoration: none;

    &:after {
        content: '';
        display: block;
        position: relative;
        margin-left: 12px;
        background: url('/dist/img/icons/arrow_right_blue_icon.svg');
        width: 12px;
        height: 12px;
    }
}

.btn-outline-light {
    &.small {
        height: 20px;
        font-size: 0.75rem;
        line-height: 20px;
        color: #a3adb9;
        margin-right: 8px;
        padding: 0 5px;

        &:hover {
            color: black;
        }
    }
}

.form-button {
    margin-top: 14px;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.range-buttons {
    background-color: #eff2f5;
    height: 36px;
    border-radius: $border-radius;
    padding: 3px 3px;

    .btn {
        min-width: 32px;
        height: 30px;
        padding: 7px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        margin-right: 6px;
        border-radius: 8px;
        color: #44566c;

        @include md-up {
            margin-right: 12px;
        }
    }

    .btn-check:checked + .btn-secondary {
        background-color: white;
        box-shadow: 0px 2px 8px rgba(48, 66, 88, 0.11);
        z-index: 9;
    }

    &.form {
        height: 78px;

        @include sm-up {
            height: 42px;
        }

        .btn {
            height: 36px;

            @include sm-up {

            }
        }

        .btn-check:checked + .btn-secondary {
            color: $primary;
        }
    }
}

.btn-outline-primary {
    border-width: 2px;
    font-weight: bold;

    &:hover {
        color: white;
    }
}

.modal-title {
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 24px;
    color: $black;
}

.modal-info {
    background: $secondary;
    border-radius: 12px;
    padding: 24px 16px;
    margin-top: 32px;
    margin-bottom: 32px;

    &.mx-width {
        max-width: 540px;
    }

    @include md-up {
        padding: 24px 36px;
    }
}

.modal-content {
    padding: 16px;

    @include md-up {
        padding: 24px;
    }
}

.modal-body {
    @include md-up {
        padding: calc(70px - 24px);
    }
}

.btn-close {
    width: 0.75rem;
    height: 0.75rem;
    opacity: .3;
}

#thanksModal {

    .btn {
        width: 100%;

        @include sm-up {
            width: auto;
        }
    }
}
.form-control {
    border: 1px solid #d2dbe3;
    border-radius: $border-radius;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 700;
    color: $black;

    @include md-up {
        padding-left: 22px;
        padding-right: 22px;
    }

    &.disabled,
    &:disabled,
    &:read-only {
        background-color: white;
        border: 1px solid #d2dbe3;
    }

    @include placeholder {
        font-weight: 700;
        font-size: 14px;
        color: $text-light;
    }
}

.form-select {
    border: 1px solid #d2dbe3;
    border-radius: $border-radius;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;

    @include md-up {
        padding-left: 22px;
        padding-right: 22px;
    }

    @include placeholder {
        font-weight: 700;
        font-size: 14px;
        color: $text-light;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: white;
}

label,
.form-label {
    color: #44566c;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 20px;
}

.input-group-text {
    //background-color: transparent;
    // border: 0;
}

.input-group-prepend {
    .input-group-text {
        height: 56px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.input-group-icon {
    position: relative;

    .icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

textarea.form-control {
    padding-top: 12px;
    padding-bottom: 12px;

    @include md-up {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.form-control {
    &.disabled-bg {
        background-color: darken(white, 5%);
        border: 1px solid #d2dbe3;
    }
}

.service-box {
    border: 1px solid #d2dbe3;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: white;
    width: 100%;
    margin-bottom: 25px;

    @include lg-up {
        margin-bottom: 0;
    }

    .service-box-img {
        height: 190px;
        border-radius: 11px 11px 0px 0px;
        background-color: #f0f5fa;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
        }
    }

    .service-box-title {
        font-size: 20px;
        font-weight: 900;
        line-height: 27px;
        margin-bottom: 1rem;
    }

    .service-box-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 2rem;
    }

    .service-box-body {
        padding: 52px 16px 32px;
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d2dbe3;
        background-color: #fff;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d2dbe3;
    background-color: #fff;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    position: relative;
}

// Footer
.icon-phone-circle {
    @include icon('phone_circle_icon.svg', 40px, 40px);
    margin-right: 12px;

    @include lg-up {
        margin-right: 16px;
    }
}

.icon-mail-circle {
    @include icon('mail_circle_icon.svg', 40px, 40px);
    margin-right: 12px;

    @include lg-up {
        margin-right: 16px;
    }
}

.icon-chart-blue {
    @include icon('chart_blue_icon.svg', 20px, 20px);
}

.icon-cart-blue {
    @include icon('cart_blue_icon.svg', 20px, 20px);
}

.icon-question-white {
    @include icon('question_white_icon.svg', 9px, 16px);
}

.icon-question-blue {
    @include icon('question_blue_icon.svg', 20px, 20px);
}

.icon-rabbit-blue {
    @include icon('rabbit_blue_icon.svg', 26px, 26px);
}

.icon-shield-blue {
    @include icon('shield_blue_icon.svg', 26px, 26px);
}

.icon-simple-blue {
    @include icon('simple_blue_icon.svg', 26px, 26px);
}

.icon-fee-blue {
    @include icon('fee_blue_icon.svg', 26px, 26px);
}

.icon-bitcoin-rounded-gold {
    @include icon('btc_rounded_gold_icon.svg', 20px, 20px);
}

.icon-zel {
    @include icon('zel.png', 20px, 20px);
}

.icon-bitcoin-rounded-grey {
    @include icon('btc_rounded_grey_icon.svg', 16px, 16px);
}

.icon-question-grey {
    @include icon('question_grey_icon.svg', 20px, 20px);
}

.icon-question-grey-white {
    @include icon('question_grey_white_icon.svg', 20px, 20px);
}

.icon-arrow-up-green {
    @include icon('arrow_up_green_icon.svg', 14px, 14px);
    margin-left: 8px;
}

.icon-arrow-down-red {
    @include icon('arrow_down_red_icon.svg', 14px, 14px);
    margin-left: 8px;
}

.icon-mail-boxed {
    @include icon('mail_boxed_icon.svg', 16px, 16px);
}

.icon-phone-boxed {
    @include icon('phone_boxed_icon.svg', 16px, 16px);
}

.icon-mail-circle-blue {
    @include icon('mail_circle_blue.svg', 56px, 56px);
}

.icon-phone-circle-blue {
    @include icon('phone_circle_blue.svg', 56px, 56px);
}


.icon-checked-green-circle {
    @include icon('checked_green_circle_icon.svg', 52px, 52px);
}

.icon-unchecked-blue-circle {
    @include icon('unchecked_blue_circle_icon.svg', 52px, 52px);
}
.footer {
    background-color: $secondary;
    padding: 56px 0;

    @include lg-up {
        padding-top: 64px;
        padding-bottom: 50px;
    }

    .nav-link {
        font-size: 0.875rem;
        color: $text-light;
        padding: 0;

        @include lg-up {
            margin-right: 32px;
        }
    }

    .container {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.footer-top {
    border-bottom: 2px solid #d2dbe3;
    padding-bottom: 56px;
    margin-bottom: 56px;

    @include lg-up {
        margin-bottom: 50px;
    }

    .footer-logo {
        margin-bottom: 64px;

        @include lg-up {
            margin-bottom: 52px;
        }
    }
}

.contact-link {
    font-size: 24px;
    line-height: 32px;
    color: #60758f;
    text-decoration: none;
}

.footer-burda {
    margin-bottom: 2rem;
}


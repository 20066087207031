@import 'abstracts/variables';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import './components/cookieconsent';
@import './components/modal_cookie';
@import '~intl-tel-input/build/css/intlTelInput.css';

body {
    overflow-x: hidden;
}

.quill {
    display: grid;
    border-top-left-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;

    .ql-toolbar {
        border-top-left-radius: $border-radius !important;
        border-top-right-radius: $border-radius !important;
    }

    .ql-editor {
        min-height: 350px;
    }

    .ql-container {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
    }
}

.quill-content {
    p {
        margin-bottom: 0;
    }

    img {
        @extend .img-fluid;
    }
}

*:focus {
    box-shadow: none !important;
    outline: none !important;
}

@import '~bootstrap';

@import 'abstracts/mixins';

@import 'base/icons';
@import 'base/buttons';
@import 'base/typography';
@import 'base/forms';
@import 'base/pagination';
@import 'base/nav';
@import 'base/admin';

@import 'components/box';
@import 'components/services';
@import 'components/stats';
@import 'components/modal';
@import 'components/errors';
@import 'components/alerts';

@import 'layout/navbar';
@import 'layout/footer';

body {
    background: linear-gradient(180deg, #f0f5fa 0%, rgba(240, 245, 250, 0) 50%);
    background-size: contain;
    background-repeat: no-repeat;
}

.container {
    padding-left: 16px;
    padding-right: 16px;
}

.section {
    padding: 80px 0;

    @include md-up {
        padding: 120px 0;
    }
}

.why-us-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    margin-bottom: 56px;

    @include md-up {
        width: 50%;
        margin-bottom: 0;
        padding: 48px 18px;
        max-width: 350px;

        &:nth-child(1) {
            border-right: 1px solid #eff2f5;
            border-bottom: 1px solid #eff2f5;
        }

        &:nth-child(2) {
            border-bottom: 1px solid #eff2f5;
        }

        &:nth-child(3) {
            border-right: 1px solid #eff2f5;
        }
    }

    @include lg-up {
        max-width: 250px;
    }

    .why-us-box-title {
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .why-us-box-text {
        color: #60758f;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 0;
    }

    .icon-container {
        background-color: #f0f5fa;
        border: 0;
        margin-bottom: 24px;
    }
}

.navbar-light .navbar-toggler {
    &[aria-expanded='true'] {
        .navbar-toggler-icon {
            transform: rotate(180deg);
            background-image: url('/dist/img/close.svg') !important;
        }
    }

    .navbar-toggler-icon {
        background-image: url('/dist/img/hamburger.svg') !important;
        height: 20px;
        background-size: contain;
        transition: 0.2s all;
    }
}

.navbar-light .navbar-toggler {
    margin-right: -0.75rem;
}

.btc {
    box-shadow: 10px 10px 20px 1px rgba(247, 147, 26, 0.15);
}

.text-dark {
    color: $text-dark !important;
}

.accordion {
    margin-bottom: 80px;

    @include md-up {
        margin-bottom: 140px;
    }
}

.accordion-item {
    background-color: #fff;
    border: 1px solid #d2dbe3;
    border-radius: 12px;
    padding: 22px 24px;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;

    @include lg-up {
        max-width: 740px;
    }
}

.accordion-button {
    background-color: transparent !important;
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    color: #203b5a !important;
    box-shadow: none !important;
    font-weight: 900;
}

.accordion-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    color: #60758f;
    line-height: 24px;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid #dbe2eb;
}

.accordion-button::after {
    content: '';
    background-image: none;
    background: #fff url('/dist/img/icons/plus_blue_icon.svg') center no-repeat;
    background-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 24px;
    color: #a5afbc;
    font-weight: bold;
    width: 24px;
    height: 24px;
    padding: 5px;
}

.accordion-button:not(.collapsed)::after {
    content: '';
    background: #fff url('/dist/img/icons/minus_blue_icon.svg') center no-repeat;
    background-size: 12px;
}

.container-small {
    @include lg-up {
        max-width: 800px;
    }
}

.question-box {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #d2dbe3;
    box-sizing: border-box;
    border-radius: 12px;
    height: 56px;
    padding: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    text-decoration: none;
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-right: calc(16px + 20px);
    transition: 0.2s all;

    @include sm-up {
        width: 100%;
    }

    &:hover {
        border-color: $primary;
        color: $primary;
    }

    &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        content: '';
        display: block;
        background: url('/dist/img/icons/arrow_right_blue_icon.svg') center no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
    }
}

.questions {
    margin-top: 80px;

    @include md-up {
        margin-top: 140px;
    }
}

body {
    min-width: 340px;
}

.contact-item {
    margin-bottom: 40px;
}
/*
#navbarSupportedContent {
    @media (max-width: 992px) {
        position: fixed;
        background: rgb(242, 246, 251);
        width: 100%;
        top: 43px;
        left: 0px;
        padding-bottom: 48px;
        z-index: 99999;
    }
}
*/

.status-item {
    position: relative;
    margin-bottom: 48px;

    &:after {
        position: absolute;
        content: '';
        display: block;
        height: 48px;
        width: 2px;
        background-color: #eaf7ec;
        top: 100%;
        left: 25px;
    }

    &.blue-line {
        &:after {
            background-color: #e7f3fc;
        }
    }

    &:first-child {
        &:after {
            display: none;
        }
    }

    &:last-child {
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }
}

.chart-container {
    position: relative;
    height: 280px;

    @include md-up {
        height: 400px;
    }
}

.contact-form-success {
    display: flex;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
    transition: 0.3s all;
    padding-left: 20px;
    padding-right: 20px;

    &.show {
        opacity: 1;
        z-index: 9;
    }

    h3 {
        max-width: 420px;
        margin-bottom: 2rem;
        font-size: 1.5rem !important;
        line-height: 1.3;

        @include md-up {
            font-size: 2rem !important;
            line-height: 44px !important;
        }
    }
}

.login-form {
    background-color: #f0f5fa;
    width: 100%;
    max-width: 520px;
}

.mb-lg {
    margin-bottom: 56px;

    @include lg-up {
        margin-bottom: 120px;
    }
}

.mb-60 {
    margin-bottom: 60px;
}

.h3 {
    font-size: 2rem !important;
    line-height: 44px;
    font-weight: 900;
}

.icon-loading {
    color: transparent !important;
    min-height: 1.6rem;
    pointer-events: none;
    position: relative;

    &::after {
        animation: loading 500ms infinite linear;
        border: 0.2rem solid white;
        border-radius: 0.8rem;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 1.2rem;
        left: 50%;
        margin-left: -0.8rem;
        margin-top: -0.8rem;
        position: absolute;
        top: 50%;
        width: 1.2rem;
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bs-alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    border-radius: 12px;
    background-color: $black;
    z-index: 999999;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem !important;
}

.form-control[type='file'].form-control-lg {
    padding: 0.5rem 1rem !important;
    font-size: 1rem !important;
    line-height: 30px;
    height: auto !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.accordion-item:first-of-type {
    border-radius: 12px !important;
}

.ql-editor {
    min-height: 150px;
}

tr {
    th,
    td {
        color: black !important;
    }
}

#loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: none;
    background: rgba(black, 0.4);
}

#loader {
    width: 3rem;
    height: 3rem;
    border: 10px solid #f3f3f3;
    border-top: 9px solid $primary;
    border-radius: 100%;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sidebar {
    @media (max-width: 768px) {
        position: absolute;
        z-index: 999;
        background-color: white;
        height: 100%;
        transform: translateX(-100%);
        transition: 0.3s all;

        &.show {
            transform: translateX(0);
        }
    }
}

#backdropNavbar {
    @media (max-width: 768px) {
        position: fixed;
        z-index: 998;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
}

.modal-sm {
    @include sm-up {
        max-width: 420px;
    }
}

th,
td {
    vertical-align: middle !important;
    white-space: nowrap !important;
    font-size: 14px;
}

#heroCanvas {
    transform: scale(3);
    margin-top: 40px;
    margin-bottom: 80px;

    @include md-up {
        margin-top: 70px;
        margin-bottom: 160px;
    }

    @include lg-up {
        transform: scale(2);
        margin-bottom: 100px;
    }

    @include xl-up {
        margin-top: 0;
        margin-top: 0px;
        transform: scale(1.4);
    }
}

.animation-container {
    overflow: hidden;
    @include lg-up {
        overflow: unset;
    }
}
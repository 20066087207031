:root {
    --bc-cookieconsent-bg-color: #f2f2f2;
    --bc-cookieconsent-text-color: #666666;
    --bc-cookieconsent-link-color: ;

    --bc-cookieconsent-accept-btn-bg-color: #39b54a;
    --bc-cookieconsent-accept-btn-bg-hover-color: #23d23b;
    --bc-cookieconsent-accept-btn-text-color: #fff;

    --bc-cookieconsent-settings-btn-bg-color: #b4b4b4;
    --bc-cookieconsent-settings-btn-bg-hover-color: #898989;
    --bc-cookieconsent-settings-btn-text-color: #ffffff;

    --bc-cookieconsent-reject-btn-bg-color: #f2f2f2;
    --bc-cookieconsent-reject-btn-text-color: #ffffff;

    --bc-cookieconsent-reject-btn-bg-color: #f3f3f3;
    --bc-cookieconsent-reject-btn-border-color: #e1e1e1;
    --bc-cookieconsent-reject-btn-border-hover-color: #999999;
    --bc-cookieconsent-reject-btn-text-color: #8e8e8e;

    --bc-cookieconsent-image-size: 142px;

    --bc-cookieconsent-zindex: 9999;

    --bc-cookieconsent-border-radius: 10px;
    --bc-cookieconsent-gap: 16px;
}

.popup-modal {
    display: none;
}

.bc-cookieconsent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: rgba(#f2f2f2, 0.92);
    color: var(--bc-cookieconsent-text-color);
    text-align: center;
    font-size: 15px;
    z-index: var(--bc-cookieconsent-zindex);
}

.bc-cookieconsent.bc-cookieconsent-hide {
    opacity: 0;
    z-index: -99;
}

.bc-cookieconsent a {
    color: var(--bc-cookieconsent-link-color);
    text-decoration: underline;
}

.bc-cookieconsent-contaienr {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 360px;
    padding-left: 20px;
    padding-right: 20px;
}

.bc-cookieconsent-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: var(--bc-cookieconsent-image-size);
    width: var(--bc-cookieconsent-image-size);
    margin-top: -50px;
}

.bc-cookieconsent-btn {
    cursor: pointer;
    width: 100%;
    height: 56px;
    border: 0;
    outline: 0;
    border-radius: var(--bc-cookieconsent-border-radius);
    padding: 12px 12px;
    font-weight: bold;
    transition: 0.3s all;
    white-space: nowrap;
}

@media (min-width: 768px) {
    .bc-cookieconsent-btn {
        padding: 12px 20px;
    }
}

.bc-cookieconsent-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: var(--bc-cookieconsent-gap);
    grid-auto-flow: row;
    grid-template-areas:
        'accept accept'
        'setting reject';
    margin: 40px 0 30px 0;
}

.accept {
    grid-area: accept;
}

.setting {
    grid-area: setting;
}

.reject {
    grid-area: reject;
}

.bc-cookieconsent-btn-accept {
    background: var(--bc-cookieconsent-accept-btn-bg-color);
    color: var(--bc-cookieconsent-accept-btn-text-color);
}

.bc-cookieconsent-btn-accept:hover {
    background: var(--bc-cookieconsent-accept-btn-bg-hover-color);
}

.bc-cookieconsent-btn-settings {
    background: var(--bc-cookieconsent-settings-btn-bg-color);
    color: var(--bc-cookieconsent-settings-btn-text-color);
}

.bc-cookieconsent-btn-settings:hover {
    background: var(--bc-cookieconsent-settings-btn-bg-hover-color);
}

.bc-cookieconsent-btn-reject {
    background: var(--bc-cookieconsent-reject-btn-bg-color);
    border: 1px solid var(--bc-cookieconsent-reject-btn-border-color);
    color: var(--bc-cookieconsent-reject-btn-text-color);
}

.bc-cookieconsent-btn-reject:hover {
    border-color: var(--bc-cookieconsent-reject-btn-border-hover-color);
}

.modal-title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #212121;
    margin-bottom: 16px;
}

.modal-text {
    display: block;
    font-size: 15px;
    text-align: center;
    color: #666666;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.modal-inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 40px;
    padding-top: 70px;
}

@media (min-width: 768px) {
    .modal-inner {
        padding: 40px;
        padding-top: 70px;
    }
}

.modal-inner .bc-cookieconsent-buttons {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 30px;
}

.bc-cookieconsent-checkbox {
    padding: 20px 0;
    border-top: 1px solid #e5e5e5;
}

.bc-cookieconsent-checkbox:last-child {
    border-bottom: 1px solid #e5e5e5;
}

#bc-modal {
    .form-check-input:checked {
        background: transparent;
    }

    .form-check {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 32px;
    }

    .form-check .text-dark {
        color: black;
        font-weight: bold;
    }

    .form-check .text-muted {
        color: #a7a7a7;
    }

    .form-check-text {
        color: #666666;
        margin-top: 8px;
    }

    .form-check-label {
        display: flex;
        flex-direction: column;
    }

    .form-check-input {
        position: relative;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        vertical-align: top;
        background-color: #fafafa;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 7px solid #f4f4f4;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        margin-right: 20px;
        cursor: pointer;
    }

    .form-check-input:checked:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 15.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='29.168px' height='20.666px' viewBox='0 0 29.168 20.666' enable-background='new 0 0 29.168 20.666' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%2339B54A' d='M11.5,20.666c-0.768,0-1.535-0.293-2.121-0.879l-8.5-8.5c-1.172-1.171-1.172-3.071,0-4.242 c1.172-1.172,3.07-1.172,4.242,0l6.379,6.379L24.047,0.878c1.172-1.171,3.072-1.171,4.242,0c1.172,1.171,1.172,3.071,0,4.243 L13.621,19.787C13.035,20.373,12.268,20.666,11.5,20.666z'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        width: 18px;
        height: 13px;
    }

    .bc-modal-close {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 0;
        background: none;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 15.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='27.125px' height='27.125px' viewBox='0 0 27.125 27.125' enable-background='new 0 0 27.125 27.125' xml:space='preserve'%3E%3Cpath fill='%23D1D3D4' d='M15.684,13.563L26.686,2.561c0.586-0.585,0.586-1.536,0-2.121c-0.586-0.586-1.535-0.586-2.121,0 L13.563,11.441L2.561,0.439c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.536,0,2.121l11.002,11.002L0.439,24.564 c-0.586,0.585-0.586,1.536,0,2.121c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439l11.002-11.002l11.002,11.002 c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439c0.586-0.585,0.586-1.536,0-2.121L15.684,13.563z'/%3E%3C/svg%3E%0A");
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.bc-modal {
	position: fixed;
	visibility: hidden;
	top: 50%;
    width: calc(100vw - 40px);
    max-width: 560px;
    height: auto;
    max-height: 95vh;
    width: 100%;
	left: 50%;
    transform: translate(-50%, -50%);
	opacity: 0;
	transition: visibility 0s linear 0.1s, opacity 0.3s ease;
	transition: .4s all;
	overflow-y: auto;
	z-index: 99999;
}

.bc-modal.show {
	position: fixed;
    display: block;
	visibility: visible;
	opacity: 1;
	
    
    background: #ffffff;
    -webkit-box-shadow: -0px 0px 35px 0px rgba(0,0,0,0.3); 
    box-shadow: -1px 0px 35px 0px rgba(0,0,0,0.3)
}

.bc-modal-backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, .95);
	opacity: 0;
	transition: opacity .4s;
	z-index: -1;
}

.bc-modal-backdrop.show {
    opacity: 1;
	z-index: 9999;
}

body.modal-open {
	overflow: hidden;
}
.ba-app,
.gh-viewport {
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    background-color: white;
}

.ba-viewport {
    display: flex;
    flex-grow: 1;
    max-height: 100%;
    width: 100vw;
}

.ba-main-nav {
    position: relative;
    z-index: 200;
    flex: 0 0 320px;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    transform: translateX(0);
    border-right: 1px solid #e6e9eb;
    transition: flex-basis 0.25s;
    overflow: hidden;
    height: 100%;
    min-width: 260px;
}

.ba-main {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.ba-nav-menu {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 96px;
    padding: 1rem;

    @include sm-up {
        padding: 2rem;
    }
}

.ba-nav-menu-details {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-right: 10px;
    min-width: 0;
}

.ba-nav-list {
    padding: 0;
    list-style: none;
    font-size: 1.4rem;
    line-height: 1.6em;

    a,
    button {
        display: flex;
        align-items: center;
        color: #40474f;
        transition: none;
        font-weight: 400;
        padding: 0.25rem 2rem;
        font-size: 1rem;
        margin: 0;
        border-radius: 0;
        box-sizing: border-box;
        text-decoration: none;
        transition: 0.1s all ease;

        &:hover,
        &.active {
            color: #394047;
            background: #f1f3f4;
            opacity: 1;
        }
    }
}

.ba-nav-main {
    margin: 24px 0;
}

.ba-main {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.ba-main-container {
    flex-grow: 1;
    padding: 0 24px 24px;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;

    @include sm-up {
        padding: 0 48px 48px;
    }
}

.ba-main-header {
    position: sticky;
    z-index: 700;
    border-bottom: 1px solid;
    border-color: transparent;

    .title {
        font-size: 2rem;
    }
}

.ba-top-header {
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #e6e9eb;
    margin-bottom: 2rem;
}

.ba-user-avatar {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background-position: 50%;
    background-size: cover;
    border-radius: 50%;
    border: 1px solid #dedede;
}

.ba-page-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(72px, auto);
    grid-gap: 1rem;
    margin: 1rem 0;
}

.ba-page-subtitle {
    font-size: 1.125rem;
}

.ba-settings-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $primary;

    &.orange {
        background-color: $orange;
    }

    &.green {
        background-color: $green;
    }
}

.ba-label-basic {
    border-radius: 12px;
    font-size: 11px;
    background-color: rgba($black, 0.5);
    color: white;
    padding: 3px 10px;
}

.ba-textarea {
    min-height: 120px !important;

    &.big {
        min-height: 250px !important;
    }
}

.table {
    color: $black;

    tr {
        &:last-child {
            th,
            td {
                border-bottom: 0;
            }
        }
    }
}

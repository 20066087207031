.rounded-box {
    box-shadow: $box-shadow;
    border-radius: 0.75rem;
    padding: 24px 16px;
    margin-bottom: 56px;

    @include md-up {
        padding: 32px;
        margin-bottom: 56px;
    }

    &.form {
        max-width: 530px;

        &.big {
            max-width: 620px;

            .row > * {
                padding-right: 8px;
                padding-left: 8px;
            }
        }

        @include xl-up {
            margin-right: 72px;
        }
    }
}

.drag-and-drop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border: 1px dashed #d2dbe3;
    box-sizing: border-box;
    border-radius: 8px;
    height: 56px;
    padding: 8px 10px;

    @include md-up {
        padding-left: 16px;
    }

    .text {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #a3adb9;
    }
}

.file-input {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    background: #ffffff !important;
    border: 1px dashed #d2dbe3 !important;
    box-sizing: border-box;
    border-radius: 8px;
    height: 56px;
    padding: 8px 10px;
}

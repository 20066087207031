* {
    font-family: 'Satoshi', sans-serif;
    //color: $text-dark;
}

a {
    color: $primary;
}

h1,
.h1 {
    font-weight: 900;
    font-size: 2rem;
    line-height: 44px;
    margin-bottom: 48px;
    color: $black;

    p {
        font-weight: 900;
        font-size: 2rem;
        line-height: 44px;
        margin-bottom: 48px;
        color: $black;
    }

    &.large {
        @include md-up {
            font-size: 3rem;
            max-width: 860px;

            p {
                font-size: 3rem;
                max-width: 860px;
            }
        }

        @include xl-up {
            font-size: 4.3rem;
            line-height: 85px;
            max-width: 860px;

            p {
                font-size: 4.3rem;
                line-height: 85px;
                max-width: 860px;
            }
        }

        @include xxl-up {
            font-size: 4.5rem;
            line-height: 93px;

            p {
                font-size: 4.5rem;
                line-height: 93px;
            }
        }
    }

    @include md-up {
        font-size: 2.75rem;
        line-height: 59px;
    }
}

.mb-172 {
    @include lg-up {
        margin-bottom: 100px;
    }
}

.subtitle {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $text-light;
    margin-bottom: 12px;

    p {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $text-light;
        margin-bottom: 12px;
    }
}

.fs-20 {
    font-size: 1.25rem;
    font-weight: 900 !important;
}

h2,
.h2 {
    font-weight: 900;
    font-size: 2rem;
    line-height: 44px;
    margin-bottom: 3rem;
    color: $black;

    p {
        font-weight: 900;
        font-size: 2rem;
        line-height: 44px;
        margin-bottom: 3rem;
        color: $black;
    }

    @include md-up {
        margin-bottom: 2.5rem;

        p {
            margin-bottom: 2.5rem;
        }
    }
}

h3,
.h3 {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 32px;
    margin-bottom: 1.5rem;
    color: $black;

    p {
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 32px;
        margin-bottom: 1.5rem;
        color: $black;
    }
}

.small {
    font-size: 12px !important;
}

.info-box {
    border: 1px solid #d2dbe3;
    border-radius: 12px;
    padding: 32px 40px;

    .info-box-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        color: $text-dark;
    }

    @include md-up {
        min-width: 354px;
    }
}

.fw-900 {
    font-weight: 900;
}

.fs-24 {
    font-size: 1.5rem;
}

p {
    line-height: 24px;
}

.alert-orange {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #f69522;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    background: #fef4e9;
    border-radius: 8px;
    padding: 0;

    span {
        color: #f69522;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 48px;
        background: #f79522;
        border-radius: 8px 0px 0px 8px;
        margin-right: 12px;

        @include sm-up {
            height: 32px;
        }
    }
}

$primary: #108be4;
$secondary: #f0f5fa;
$light: #e5e5e5;
$text-light: #a3adb9;
$text-dark: #60758f;
$black: #0a1324;

$green: #2faf43;
$red: #d34242;

$danger: $red;
$success: $green;

$text-muted: #a3adb9;

$breadcrumb-divider: quote('>');
$breadcrumb-divider-color: #bbbec2;

$border-radius: 0.5rem;
$box-shadow: 0px 4px 48px rgba(48, 66, 88, 0.13);

$tooltip-max-width: 280px;

$modal-content-border-radius: $border-radius;
$modal-content-border-width: 0;
$modal-md: 700px;


$table-color: #f1f3f4;
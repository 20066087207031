.error-page {
    min-height: calc(100vh - 389px - 64px - 48px);

    @include lg-up {
        min-height: calc(100vh - 332px - 122px - 80px);
    }
}

.error-title {
    font-weight: 700;
    font-size: 120px;
    line-height: 162px;

    @include md-up {
        font-weight: 700;
        font-size: 240px;
        line-height: 324px;
    }
}

.error-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-dark;
    margin-bottom: 32px;
}

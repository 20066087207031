.stat-box {
    margin-bottom: 28px;

    @include lg-up {
        margin-bottom: 0;
    }

    .small {
        margin-bottom: 10px;
        font-weight: bold;
        white-space: nowrap;
    }
}